<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 15">
        <path d="M5.5 14.0625c-2.53229 0-4.583333-2.0977-4.583333-4.6875 0-.47461.174743-1.14844.524223-1.97754.34088-.80859.8164-1.68457 1.33489-2.54297C3.80417 3.15527 4.96432 1.60547 5.48281.9375h.03724c.51849.6709 1.67865 2.21777 2.70703 3.91699.51849.85547.99115 1.73145 1.3349 2.54297.34661.8291.52132 1.50293.52132 1.97754 0 2.5898-2.05101 4.6875-4.5833 4.6875ZM0 9.375C0 12.4805 2.46354 15 5.5 15S11 12.4805 11 9.375C11 6.70312 7.27031 1.69043 6.2276.342773 6.05573.123047 5.80078 0 5.52578 0h-.05156c-.275 0-.52995.123047-.70182.342773C3.72969 1.69043 0 6.70312 0 9.375Zm3.20833 0c0-.25781-.20625-.46875-.45833-.46875s-.45833.21094-.45833.46875c0 1.8135 1.43515 3.2812 3.20833 3.2812.25208 0 .45833-.2109.45833-.4687 0-.2578-.20625-.4687-.45833-.4687-1.26615 0-2.29167-1.0489-2.29167-2.3438Z"/>
    </svg>
)

</script>
